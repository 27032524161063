/* global process */
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import firebaseConfig from './config'

firebase.initializeApp(firebaseConfig) // eslint-disable-line

const firestore = firebase.firestore()

// if (process.env.NODE_ENV === 'development') {
//   // use firestore emulator
//   firestore.settings({
//     host: 'localhost:8080',
//     ssl: false,
//   })
// }

export default {
  auth: firebase.auth(),
  storage: firebase.storage(),
  firestore,
}
