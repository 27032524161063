import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import compose from 'lodash/fp/compose'

import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Hidden from '@material-ui/core/Hidden'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import ColorlibStepIcon, {
  ColorlibConnector,
} from './subcomponents/ColorlibStepIcon'
import { modalSteps, getContentByStepNumber } from './constants'
import { withTenantContext } from 'context/TenantContext'
import { useSnackbar } from 'context/SnackbarContext'
import firebase from '../../firebase/firebase'
import { withAuthContext } from 'context/AuthContext'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  nextButton: {
    marginLeft: 20,
  },
  contentWrapper: {
    marginTop: 20,
    marginBottom: 20,
    flexGrow: 1,
  },
}))

const propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  user: PropTypes.object,
  confirmarTurno: PropTypes.func,
  especialidades: PropTypes.object,
}

const formInitialValues = {
  modalidad: 'presencial',
  especialidad: '',
  medico: '',
  fecha: '',
  hora: '',
}


const NuevoTurnoModal = ({
  open,
  handleClose,
  user,
  confirmarTurno,
  especialidades,
  fetchAgendaPaciente,
}) => {
  const [activeStepNumber, setActiveStepNumber] = useState(0)
  const { showSuccessMsg, showErrorMsg } = useSnackbar()
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const isLastStep = activeStepNumber === modalSteps.length - 1
  const ContentCmp = getContentByStepNumber(activeStepNumber)

  const nextStep = () => {
    setActiveStepNumber(activeStepNumber + 1)
  }

  const prevStep = () => {
    setActiveStepNumber(activeStepNumber - 1)
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true)
      const { hora, fecha, medico, especialidad, modalidad } = values

      const dbUser = await firebase.firestore
        .collection('users')
        .doc(user.uid)
        .get()

      const { dni, name, lastName } = dbUser.data()
      const result = await confirmarTurno({
        medico: {
          id: medico,
          nombre: especialidades[especialidad][medico].nombre,
          email: especialidades[especialidad][medico].email,
        },
        paciente: {
          dni,
          nombre: name,
          apellido: lastName,
        },
        turno: {
          fecha: fecha.format('DD/MM/YYYY'),
          hora,
          modalidad,
          especialidad,
        },
      })

      if (result) {
        handleClose()
        fetchAgendaPaciente(dni)
        setActiveStepNumber(0)
        resetForm()
        showSuccessMsg('Turno guardado con éxito!')
      }
    } catch (error) {
      console.error(error)
      setSubmitting(false)
      showErrorMsg('Hubo un error al intentar registrar el turno')
    }
  }

  /**
   * Custom validation function. Yup was over-complicated for this scenario.
   * Validate that all steps have valid values up until the current step!
   */
  const validateSchema = (values) => {
    const errors = Array.from(Array(activeStepNumber + 1).keys()).reduce(
      (acc, current) => {
        return {
          ...acc,
          ...modalSteps[current].validateStep(values),
        }
      },
      {},
    )

    return errors
  }

  return (
    <Formik
      validate={validateSchema}
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
    >
      {({ resetForm, submitForm, isValid, setFieldValue, isSubmitting }) => {
        const onClose = () => {
          handleClose()
          resetForm()
          setActiveStepNumber(0)
        }

        return (
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableAutoFocus
            disableEnforceFocus
          >
            <Box display="flex" justifyContent="space-between">
              {/* Hide the title for this step, since the calendar is too big */}
              {modalSteps[activeStepNumber].step !== 'fecha' && (
                <DialogTitle>Obtener turno</DialogTitle>
              )}
              <Hidden smUp>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Hidden>
            </Box>
            <DialogContent className={classes.dialogContent}>
              <Box textAlign="center" className={classes.contentWrapper}>
                <ContentCmp />
              </Box>
              <Hidden xsDown>
                <Stepper
                  alternativeLabel
                  activeStep={activeStepNumber}
                  connector={<ColorlibConnector />}
                >
                  {modalSteps.map(({ step, label }) => (
                    <Step key={step}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {/* {label} */}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <DialogActions>
                  {activeStepNumber > 0 && (
                    <Button
                      onClick={() => {
                        // TODO refactor, improve
                        if (modalSteps[activeStepNumber].resetStep) {
                          modalSteps[activeStepNumber].resetStep(setFieldValue)
                        } else {
                          setFieldValue(modalSteps[activeStepNumber].step, '')
                        }
                        prevStep()
                      }}
                    >
                      Volver
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      if (isLastStep) {
                        submitForm()
                      } else {
                        nextStep()
                      }
                    }}
                    className={classes.nextButton}
                    color="secondary"
                    variant="contained"
                    disabled={!isValid}
                    startIcon={
                      isSubmitting && (
                        <CircularProgress color="white" size={20} />
                      )
                    }
                  >
                    {isLastStep ? 'Confirmar turno' : 'Siguiente'}
                  </Button>
                </DialogActions>
              </Hidden>
              <Hidden smUp>
                <MobileStepper
                  variant="dots"
                  steps={modalSteps.length}
                  position="static"
                  activeStep={activeStepNumber}
                  nextButton={
                    <Button
                      onClick={() => {
                        if (isLastStep) {
                          submitForm()
                        } else {
                          nextStep()
                        }
                      }}
                      className={classes.nextButton}
                      color="secondary"
                      variant="contained"
                      disabled={!isValid}
                      startIcon={
                        isSubmitting && (
                          <CircularProgress color="white" size={20} />
                        )
                      }
                    >
                      {isLastStep ? 'Confirmar turno' : 'Siguiente'}
                    </Button>
                  }
                  backButton={
                    <Button
                      onClick={() => {
                        // TODO refactor, improve
                        if (modalSteps[activeStepNumber].resetStep) {
                          modalSteps[activeStepNumber].resetStep(setFieldValue)
                        } else {
                          setFieldValue(modalSteps[activeStepNumber].step, '')
                        }
                        prevStep()
                      }}
                      style={{
                        visibility: activeStepNumber > 0 ? 'visible' : 'hidden',
                      }}
                    >
                      Volver
                    </Button>
                  }
                />
              </Hidden>
            </DialogContent>
          </Dialog>
        )
      }}
    </Formik>
  )
}

NuevoTurnoModal.propTypes = propTypes

export default compose(
  withTenantContext(
    ({ especialidades, confirmarTurno, fetchAgendaPaciente }) => ({
      especialidades,
      confirmarTurno,
      fetchAgendaPaciente,
    }),
  ),
  withAuthContext,
)(NuevoTurnoModal)
