import React, { memo, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import NuevoTurnoModal from 'components/NuevoTurnoModal'
import { useTenantContext } from 'context/TenantContext'
import { useDialog } from 'context/DialogContext'

const useStyles = makeStyles(() => ({
  paper: {
    padding: 24,
  },
  cardTitle: {
    textAlign: 'left',
  },
}))

const NuevoTurnoCard = () => {
  const { agendaPaciente } = useTenantContext()
  const showDialog = useDialog()
  const classes = useStyles()
  const [nuevoTurnoModalOpen, setNuevoTurnoModalOpen] = useState(false)

  const onNuevoTurnoClick = () => {
    if (agendaPaciente && agendaPaciente.length) {
      showDialog({
        title: 'Atención',
        message:
          'No es posible obtener un turno nuevo cuando ya tenés turnos pendientes.',
      })
      return
    }

    setNuevoTurnoModalOpen(true)
  }

  return (
    <Grid item xs={12} md={5}>
      <Paper className={classes.paper} elevation={1}>
        <Typography className={classes.cardTitle} variant="h6" gutterBottom>
          {agendaPaciente.length
            ? 'Obtener nuevo turno'
            : 'Aún no tenés turnos'}
        </Typography>
        <Typography style={{ textAlign: 'left' }} variant="body1" gutterBottom>
          Obtener turnos online es sencillo. Hacé click en el siguiente enlace y
          registrá tu turno.
        </Typography>
        <br />
        <Button
          onClick={onNuevoTurnoClick}
          variant="contained"
          color="secondary"
        >
          Nuevo turno
        </Button>
      </Paper>
      <NuevoTurnoModal
        open={nuevoTurnoModalOpen}
        handleClose={() => setNuevoTurnoModalOpen(false)}
      />
    </Grid>
  )
}

export default memo(NuevoTurnoCard)
