import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import isEmpty from 'lodash/isEmpty'

import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useDialog } from 'context/DialogContext'
import { useSnackbar } from 'context/SnackbarContext'
import firebase from '../../../../firebase/firebase'
import MeetingDetails from './MeetingDetails'

const propTypes = {
  turno: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fetchAgendaPaciente: PropTypes.func.isRequired,
  eliminarTurno: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'flex-start',
    marginBottom: 12,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    backgroundColor: 'rgb(136, 136, 136)',
    marginRight: 24,
    marginTop: 8,
  },
  deleteButton: {
    textTransform: 'none',
    minWidth: 100,
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
      marginBottom: 12,
      alignSelf: 'flex-end',
    },
  },
  label: {
    fontWeight: 600,
    marginRight: 8,
  },
  turnoDetails: {
    '& *': {
      marginBottom: 2,
    },
    flexGrow: 1,
  },
  turnoDetailsItem: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}))

const TurnoListItem = ({
  turno: { medico, especialidad, fecha, hora, metadata },
  user,
  eliminarTurno,
  fetchAgendaPaciente,
}) => {
  const showDialog = useDialog()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const { showErrorMsg, showSuccessMsg } = useSnackbar()

  const cancelarTurno = async ({ medico, fecha, hora }) => {
    try {
      setIsLoading(true)
      const dbUser = await firebase.firestore
        .collection('users')
        .doc(user.uid)
        .get()

      const { dni, email } = dbUser.data()
      const eliminarRes = await eliminarTurno({
        medico,
        fecha,
        hora,
        dni,
        email,
      })

      if (eliminarRes) {
        showSuccessMsg('Turno cancelado con éxito.')
        fetchAgendaPaciente(dni)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      showErrorMsg('Ocurrió un error al intentar cancelar el turno')
      console.error(error)
    }
  }

  const onCancelTurno = ({ medico, fecha, hora }) => () => {
    showDialog({
      title: 'Cancelar turno',
      message: '¿Deseas cancelar el turno seleccionado?',
      onAction: () => cancelarTurno({ medico, fecha, hora }),
    })
  }

  return (
    <ListItem
      className={classes.listItemRoot}
      key={`${medico}${especialidad}${fecha}${hora}`}
    >
      <Hidden mdDown implementation="css">
        <Avatar className={classes.icon}>
          <EventAvailableIcon />
        </Avatar>
      </Hidden>
      <Box
        className={classes.turnoDetails}
        display="flex"
        flexDirection="column"
      >
        <div className={classes.turnoDetailsItem}>
          <Typography className={classes.label} variant="body1">
            Fecha:
          </Typography>
          <Typography variant="body1">{`${fecha} - ${hora}`}</Typography>
        </div>
        <div className={classes.turnoDetailsItem}>
          <Typography className={classes.label} variant="body1">
            Médico/a:
          </Typography>
          <Typography variant="body1">{startCase(toLower(medico))}</Typography>
        </div>
        <div className={classes.turnoDetailsItem}>
          <Typography className={classes.label} variant="body1">
            Especialidad:
          </Typography>
          <Typography variant="body1">
            {startCase(toLower(especialidad))}
          </Typography>
        </div>
        {metadata && !isEmpty(metadata) && (
          <>
            <div className={classes.turnoDetailsItem}>
              <Typography className={classes.label} variant="body1">
                Modalidad:
              </Typography>
              <Typography variant="body1">
                {startCase(metadata.modalidad)}
              </Typography>
            </div>
            {metadata.meeting && metadata.meeting.id && (
              <MeetingDetails meeting={metadata.meeting} />
            )}
          </>
        )}
      </Box>
      <Button
        className={classes.deleteButton}
        startIcon={
          isLoading ? (
            <CircularProgress color="white" size={20} />
          ) : (
            <DeleteIcon />
          )
        }
        disabled={isLoading}
        variant="contained"
        onClick={onCancelTurno({
          medico,
          especialidad,
          fecha,
          hora,
        })}
      >
        Cancelar
      </Button>
    </ListItem>
  )
}

TurnoListItem.propTypes = propTypes

export default memo(TurnoListItem)
