import React, { useEffect, useState, memo } from 'react'

import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import makePrivate from 'components/makePrivate'
import TopBar from 'components/TopBar'
import SideDrawer from 'components/SideDrawer'
import { useTenantContext } from 'context/TenantContext'
import { useAuth } from 'context/AuthContext'
import { useSnackbar } from 'context/SnackbarContext'

import firebase from '../../firebase/firebase'
import { ProgressWrapper } from '../../components/AuthPage/styles'
import ListTurnos from './subcomponents/ListTurnos'
import NuevoTurnoCard from './subcomponents/NuevoTurnoCard'
import FillUserData from './subcomponents/FillUserData'

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    paddingLeft: 265,
    marginTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  contentGrid: {
    padding: 24,
  },
}))

const Home = () => {
  const classes = useStyles()
  const { finishedAuthCheck, user } = useAuth()
  const { agendaPaciente, fetchAgendaPaciente } = useTenantContext()
  const { showErrorMsg } = useSnackbar()
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    firebase.firestore
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (user) => {
        if (!user.exists) {
          firebase.auth.signOut()
          showErrorMsg('No existe un perfil para este usuario')
          return
        }

        setUserData(user.data())

        try {
          await fetchAgendaPaciente(user.data().dni)
        } catch (error) {
          showErrorMsg('Error al intentar obtener la agenda del paciente')
        }
      })
  }, [fetchAgendaPaciente, user.uid]) // ignore: showErrorMsg

  return (
    <div>
      <CssBaseline />
      <TopBar />
      <SideDrawer />
      <Box className={classes.contentWrapper}>
        {!finishedAuthCheck || !agendaPaciente ? (
          <ProgressWrapper>
            <CircularProgress />
          </ProgressWrapper>
        ) : (
          <Grid className={classes.contentGrid} container spacing={3}>
            <NuevoTurnoCard />
            <ListTurnos />
            <FillUserData userData={userData} />
          </Grid>
        )}
      </Box>
    </div>
  )
}

export default memo(makePrivate(Home))
