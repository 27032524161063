import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, useFormikContext } from 'formik'

import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel'
import Box from '@material-ui/core/Box'

import { withTenantContext } from 'context/TenantContext'

const propTypes = {
  turnosDisponibles: PropTypes.object,
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: 250,
    marginTop: 20,
  },
}))

const SelectHora = ({ turnosDisponibles }) => {
  const classes = useStyles()
  const { validateForm, values } = useFormikContext()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FormLabel component="legend">Seleccioná un horario</FormLabel>
      <Field name="hora">
        {({ field }) => (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="hora">Horario</InputLabel>
            <Select input={<OutlinedInput {...field} name="hora" id="hora" />}>
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {turnosDisponibles[
                values.fecha && values.fecha.format('DD/MM/YYYY')
              ].map((horario) => (
                <MenuItem key={horario} value={horario}>
                  {horario}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Field>
    </Box>
  )
}

SelectHora.propTypes = propTypes

export default withTenantContext(({ turnosDisponibles }) => ({
  turnosDisponibles,
}))(SelectHora)
