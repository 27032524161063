import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import get from 'lodash/get'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormikTextField from 'components/formik/FormikTextField'

import { useAuth } from 'context/AuthContext'
import { useSnackbar } from 'context/SnackbarContext'
import firebase from '../../../../firebase/firebase'

// (351) 2432546 or 3512312434 or 3573-34234234  should be considered as valid
const phoneRegExp = /^((\([1-9]{2,5}\)\s?)|([1-9]{2,5}[\s-]?))?[0-9]{3,15}[\s-]?[0-9]{0,8}$/

const requiredFields = ['name', 'lastName', 'phoneNumber']

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(20, 'Tu nombre no puede tener más de 20 caracteres')
    .required('Nombre es un campo obligatorio'),
  lastName: Yup.string()
    .max(20, 'Tu apellido no puede tener más de 20 caracteres')
    .required('Apellido es un campo obligatorio'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Teléfono no válido')
    .min(7, 'Tu teléfono no puede tener menos de 7 caracteres')
    .max(20, 'Tu teléfono no puede tener más de 20 caracteres')
    .required('Teléfono es un campo obligatorio'),
})

const propTypes = {
  userData: PropTypes.object,
}

const FillUserData = ({ userData }) => {
  const [open, setOpen] = useState(false)
  const { showErrorMsg } = useSnackbar()

  const { user } = useAuth()

  useEffect(() => {
    if (userData) {
      if (requiredFields.some((field) => !userData[field])) {
        setOpen(true)
      }
    }
  }, [userData])

  const handleSubmit = async ({ name, lastName, phoneNumber }) => {
    try {
      await firebase.firestore.collection('users').doc(user.uid).update({
        name,
        lastName,
        phoneNumber,
      })

      setOpen(false)
    } catch (error) {
      console.error(error)
      showErrorMsg('Hubo un error al intentar actualizar tus datos')
    }
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{
        name: get(userData, 'name', ''),
        lastName: get(userData, 'lastName', ''),
        phoneNumber: get(userData, 'phoneNumber', ''),
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, submitForm }) => {
        return (
          <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
              Completá tu información
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Para continuar usando este sitio, por favor completá los
                siguientes datos personales.
              </DialogContentText>
              <FormikTextField
                autoFocus
                margin="dense"
                label="Nombre"
                fullWidth
                name="name"
              />
              <FormikTextField
                margin="dense"
                label="Apellido"
                fullWidth
                name="lastName"
              />
              <FormikTextField
                margin="dense"
                label="Teléfono"
                fullWidth
                name="phoneNumber"
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={!isValid}
                onClick={submitForm}
                variant="contained"
                color="secondary"
              >
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        )
      }}
    </Formik>
  )
}

FillUserData.propTypes = propTypes

export default memo(FillUserData)
