// import LaptopMacIcon from '@material-ui/icons/LaptopMac'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import ScheduleIcon from '@material-ui/icons/Schedule'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import SelectEspecialidad from './subcomponents/SelectEspecialidad'
import SelectFecha from './subcomponents/SelectFecha'
import SelectHora from './subcomponents/SelectHora'
// import SelectModalidad from './subcomponents/SelectModalidad'
import Summary from './subcomponents/Summary'

export const modalSteps = [
  // {
  //   step: 'modalidad',
  //   label: 'Seleccioná la modalidad del turno',
  //   content: SelectModalidad,
  //   icon: LaptopMacIcon,
  //   validateStep: (values) =>
  //     values.modalidad ? {} : { modalidad: 'Incomplete value' },
  // },
  {
    step: 'especialidad',
    label: 'Seleccioná la especialidad y el médico',
    content: SelectEspecialidad,
    icon: LocalHospitalIcon,
    validateStep: (values) =>
      values.especialidad && values.medico
        ? {}
        : { especialidad: 'Incomplete value', medico: 'Incomplete value' },
    resetStep: (setFieldValue) => {
      setFieldValue('especialidad', '')
      setFieldValue('medico', '')
    },
  },
  {
    step: 'fecha',
    label: 'Buscá una fecha disponible',
    content: SelectFecha,
    icon: EventAvailableIcon,
    validateStep: (values) =>
      values.fecha ? {} : { fecha: 'Incomplete value' },
  },
  {
    step: 'hora',
    label: 'Elegí un horario',
    content: SelectHora,
    icon: ScheduleIcon,
    validateStep: (values) => (values.hora ? {} : { hora: 'Incomplete value' }),
  },
  {
    step: 'summary',
    label: 'Revisá la información y confirmá el turno',
    content: Summary,
    icon: CheckCircleIcon,
    validateStep: () => {},
  },
]

export const getContentByStepNumber = (stepNumber) =>
  modalSteps[stepNumber].content

export const getIconByStepNumber = (stepNumber) => modalSteps[stepNumber].icon
