import React, { memo } from 'react'
import PropTypes from 'prop-types'
import parse from 'date-fns/parse'
import isSameDay from 'date-fns/isSameDay'
import format from 'date-fns/format'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Alert from '@material-ui/lab/Alert'

import { useTenantContext } from 'context/TenantContext'
import { useAuth } from 'context/AuthContext'
import TurnoListItem from './TurnoListItem'

const useStyles = makeStyles(() => ({
  turnosList: {
    maxHeight: '70vh',
    overflow: 'scroll',
    width: '100%',
  },
  paper: {
    padding: 24,
  },
  cardTitle: {
    textAlign: 'left',
  },
  alert: {
    marginTop: 8,
    paddingBottom: 0,
  },
  alertLabel: {
    fontSize: 14,
  },
}))

const propTypes = {
  selectedDate: PropTypes.object,
}

const ListTurnos = ({ selectedDate }) => {
  const { agendaMedico } = useTenantContext()
  const classes = useStyles()
  const { user } = useAuth()

  if (!agendaMedico.length) {
    return null
  }

  const filteredTurnos = agendaMedico.filter(({ fecha }) => {
    const parsedDate = parse(fecha, 'dd/MM/yyyy', new Date())
    return isSameDay(parsedDate, selectedDate)
  })

  return (
    <Grid item xs={12} md={7}>
      <Paper className={classes.paper} elevation={1}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography
            className={classes.cardTitle}
            variant="h6"
            color="textPrimary"
            gutterBottom
          >
            {selectedDate
              ? `Turnos para el ${format(selectedDate, 'dd/MM/yyyy')}`
              : 'Seleccioná una fecha'}
          </Typography>
          <div className={classes.turnosList}>
            {filteredTurnos.map((turno, idx) => (
              <>
                <TurnoListItem turno={turno} user={user} key={idx} />
                <Divider variant="inset" light />
              </>
            ))}
            {selectedDate && !filteredTurnos.length && (
              <Alert className={classes.alert} severity="info">
                <Typography className={classes.alertLabel} variant="body1">
                  No hay turnos para el {format(selectedDate, 'dd/MM/yyyy')}.
                  Navegá en el calendario para visualizar los turnos registrados
                  en los días destacados.
                </Typography>
              </Alert>
            )}
            {!selectedDate && !filteredTurnos.length && (
              <Alert className={classes.alert} severity="info">
                <Typography className={classes.alertLabel} variant="body1">
                  Navegá en el calendario para visualizar los turnos registrados
                  en los días destacados.
                </Typography>
              </Alert>
            )}
          </div>
        </Box>
      </Paper>
    </Grid>
  )
}

ListTurnos.propTypes = propTypes

export default memo(ListTurnos)
