/* global process */

import React, { useEffect } from 'react'
import { object } from 'prop-types'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { ThemeProvider } from 'styled-components'
import ReactGA from 'react-ga'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Home from './pages/Home'
import HomeMedico from './pages/HomeMedico'
import AuthPage from './components/AuthPage'
import theme from './theme'
import { LayoutProvider } from './context/LayoutContext'
import { DialogProvider } from './context/DialogContext'
import { SnackbarProvider } from './context/SnackbarContext'
import { TenantProvider } from './context/TenantContext'
import { AuthProvider } from './context/AuthContext'

const Routes = ({ match }) => (
  <ThemeProvider theme={theme}>
    <AuthProvider tenant={match.params.tenant}>
      <TenantProvider tenant={match.params.tenant}>
        <DialogProvider>
          <LayoutProvider>
            <SnackbarProvider>
              <Switch>
                <Route
                  exact
                  path="/:tenant/"
                  component={(props) => <AuthPage mode="paciente" {...props} />}
                />
                <Route
                  exact
                  path="/:tenant/login-medico"
                  component={(props) => <AuthPage mode="medico" {...props} />}
                />
                <Route exact path="/:tenant/home" component={Home} />
                <Route
                  exact
                  path="/:tenant/home-medico"
                  component={HomeMedico}
                />
                <Route component={() => <h3>Not found</h3>} />
              </Switch>
            </SnackbarProvider>
          </LayoutProvider>
        </DialogProvider>
      </TenantProvider>
    </AuthProvider>
  </ThemeProvider>
)

Routes.propTypes = {
  match: object.isRequired,
}

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('UA-63278334-12')
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }, [])

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/:tenant" component={Routes} />
          <Route component={() => <Redirect to="/cigor" />} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
