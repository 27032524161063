/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { object, func, bool, oneOf } from 'prop-types'
import { Redirect } from 'react-router-dom'
import compose from 'lodash/fp/compose'

import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { withTenantContext } from '../../context/TenantContext'
import { withAuthContext } from '../../context/AuthContext'
import firebase from '../../firebase/firebase'
import {
  ProgressWrapper,
  Main,
  StyledPaper,
  StyledForm,
  SubmitWrapper,
  LogoImg,
} from './styles'

const AuthPage = ({
  tenant: { logo, name },
  setUser,
  user: authUser,
  finishedAuthCheck,
  match,
  mode,
}) => {
  const [form, setForm] = useState({
    email: '',
    dni: '',
  })
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const onInputChange = (field) => (evt) =>
    setForm({
      ...form,
      [field]: evt.target.value,
    })

  const onSubmit = async (e) => {
    e.preventDefault()
    const { email, dni } = form
    setLoading(true)
    try {
      const { user } = await firebase.auth.createUserWithEmailAndPassword(
        email,
        dni,
      )
      setLoading(false)
      setErr('')
      setUser(user)

      // Store user data
      firebase.firestore
        .collection('users')
        .doc(user.uid)
        .set({
          ...form,
          rol: mode,
        })
    } catch (error) {
      console.error(error)
      if (error.code === 'auth/email-already-in-use') {
        try {
          const { user } = await firebase.auth.signInWithEmailAndPassword(
            email,
            dni,
          )
          setLoading(false)
          setUser({
            ...user,
            dni,
          })
        } catch (fail) {
          // eslint-disable-line
          console.error(fail)
          setLoading(false)
          setErr('Los datos ingresados no son válidos')
        }
      } else {
        setLoading(false)
        setErr('Los datos ingresados no son válidos')
      }
      console.error(error)
    }

    return false
  }

  if (!finishedAuthCheck) {
    return (
      <ProgressWrapper>
        <CircularProgress />
      </ProgressWrapper>
    )
  }

  return authUser ? (
    <Redirect to={mode === 'paciente' ? `${match.url}/home` : 'home-medico'} />
  ) : (
    <Main>
      <CssBaseline />
      <StyledPaper mode={mode}>
        <LogoImg src={logo} alt={name} />
        <br />
        <Typography variant="title" gutterBottom>
          Portal de {mode === 'paciente' ? 'pacientes' : 'médicos'}
        </Typography>
        {loading ? (
          <ProgressWrapper>
            <CircularProgress />
          </ProgressWrapper>
        ) : (
          <>
            <StyledForm onSubmit={onSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Dirección de email</InputLabel>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  onChange={onInputChange('email')}
                  autoFocus
                  value={form.email}
                />
              </FormControl>
              {mode === 'paciente' && (
                <>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="dni">DNI</InputLabel>
                    <Input
                      name="dni"
                      type="dni"
                      id="dni"
                      autoComplete="current-dni"
                      onChange={onInputChange('dni')}
                      value={form.dni}
                    />
                  </FormControl>
                </>
              )}
              {mode === 'medico' && (
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    name="password"
                    type="text"
                    id="password"
                    autoComplete="current-password"
                    onChange={onInputChange('dni')}
                    value={form.dni}
                  />
                </FormControl>
              )}
              {err && <p style={{ color: 'red' }}>{err}</p>}
              <SubmitWrapper>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Ingresar
                </Button>
              </SubmitWrapper>
            </StyledForm>
            <br />
            {mode === 'paciente' && (
              <a href={`${match.url}/login-medico`}>¿Sos médico?</a>
            )}
          </>
        )}
      </StyledPaper>
    </Main>
  )
}

AuthPage.propTypes = {
  tenant: object.isRequired,
  setUser: func.isRequired,
  user: object,
  finishedAuthCheck: bool.isRequired,
  match: object.isRequired,
  mode: oneOf(['medico', 'paciente']).isRequired,
}

AuthPage.defaultProps = {
  user: null,
}

export default compose(withAuthContext, withTenantContext())(AuthPage)
