/* global process */
import React, { createContext, useContext } from 'react'
import { string } from 'prop-types'
import { node } from 'prop-types'
import axios from 'axios'
import ReactGA from 'react-ga'

import moment from 'moment'
import startCase from 'lodash/fp/startCase'
import identity from 'lodash/identity'

import data from '../tenantsData'
import firebase from '../firebase/firebase'

const TenantContext = createContext()

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status) => {
    return status < 400
  },
})

class TenantProvider extends React.PureComponent {
  static propTypes = {
    tenant: string.isRequired,
  }

  constructor(props) {
    super(props)

    if (!data[props.tenant]) {
      window.location.href = '/'
    }

    this.state = {
      tenant: data[props.tenant], // eslint-disable-line
      especialidades: {},
      medicos: [],
      turnosDisponibles: {},
      agendaPaciente: null,
      agendaMedico: null,
      loading: false,
    }
  }

  componentDidMount() {
    this.fetchEspecialidades()
  }

  fetchEspecialidades = async () => {
    const { medicos: stateMedicos } = this.state
    const startTime = new Date().getTime()

    this.setState({
      loading: true,
    })

    if (!stateMedicos.length) {
      const { data } = await axiosInstance.get('/api/especialidades')

      ReactGA.timing({
        category: 'HTTP Requests',
        variable: 'getEspecialidades',
        value: new Date().getTime() - startTime,
        label: 'GET Especialidades',
      })

      this.setState({
        medicos: data.medicos,
        especialidades: data.especialidades,
      })
    }

    this.setState({
      loading: false,
    })
  }

  /* Turnos que el paciente reservó */
  fetchAgendaPaciente = async (dni, startMoment = moment()) => {
    try {
      const startTime = new Date().getTime()
      const idToken = await firebase.auth.currentUser.getIdToken()

      this.setState({
        loading: true,
      })

      const { data } = await axiosInstance.get(
        `/api/agendaPaciente?dni=${dni}&startDate=${startMoment.format(
          'DD/MM/YYYY',
        )}`,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        },
      )

      ReactGA.timing({
        category: 'HTTP Requests',
        variable: 'getAgendaPaciente',
        value: new Date().getTime() - startTime,
        label: 'GET Agenda Paciente',
      })

      this.setState({
        agendaPaciente: data.agendaPaciente,
        loading: false,
      })
    } catch (error) {
      console.error(error)
      // Set an empty array so that we render as if the user has no turnos assigned.
      // We're using null just as the initial state to display the spinner.
      this.setState({
        agendaPaciente: [],
        loading: false,
      })

      throw error
    }
  }

  /* Turnos que un médico tiene asignados */
  fetchAgendaMedico = async (email, startMoment = moment()) => {
    try {
      const startTime = new Date().getTime()
      const idToken = await firebase.auth.currentUser.getIdToken()

      this.setState({
        loading: true,
      })

      const { data } = await axiosInstance.get(
        `/api/agendaMedico?email=${email}&startDate=${startMoment.format(
          'DD/MM/YYYY',
        )}`,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        },
      )

      ReactGA.timing({
        category: 'HTTP Requests',
        variable: 'getAgendaMedico',
        value: new Date().getTime() - startTime,
        label: 'GET Agenda Médico',
      })

      this.setState({
        agendaMedico: data.agenda,
        loading: false,
      })
    } catch (error) {
      console.error(error)
      // Set an empty array so that we render as if the user has no turnos assigned.
      // We're using null just as the initial state to display the spinner.
      this.setState({
        agendaMedico: [],
        loading: false,
      })
    }
  }

  /* Turnos disponibles */
  fetchTurnosDisponibles = async (idMedico, startMoment = moment()) => {
    try {
      const startTime = new Date().getTime()
      this.setState({ loading: true })

      const { data } = await axiosInstance.get(
        `/api/turnosDisponibles?idMedico=${idMedico}&startDate=${startMoment.format(
          'DD/MM/YYYY',
        )}`,
      )

      ReactGA.timing({
        category: 'HTTP Requests',
        variable: 'getTurnosDisponibles',
        value: new Date().getTime() - startTime,
        label: 'GET Turnos Disponibles',
      })

      this.setState({
        turnosDisponibles: data.turnosDisponibles,
        loading: false,
      })
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false,
      })
    }
  }

  formatFullName = (apellido, nombre) => {
    const format = (str) => (!str ? '' : startCase(str.toLowerCase().trim()))

    return `${format(apellido)}, ${format(nombre)}`
  }

  confirmarTurno = async ({ medico, paciente, turno }) => {
    try {
      const startTime = new Date().getTime()
      this.setState({
        loading: true,
      })

      const idToken = await firebase.auth.currentUser.getIdToken()

      const {
        data: { result },
      } = await axiosInstance.post(
        '/api/confirmarTurno',
        {
          medico,
          paciente,
          turno,
        },
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        },
      )

      ReactGA.timing({
        category: 'HTTP Requests',
        variable: 'confirmarTurno',
        value: new Date().getTime() - startTime,
        label: 'POST Confirmar Turno',
      })

      ReactGA.event({
        category: 'Turnos',
        action: 'Confirmar turno',
      })

      this.setState({
        loading: false,
      })

      return result
    } catch (error) {
      this.setState({
        loading: false,
      })

      throw error
    }
  }

  eliminarTurno = async ({ medico, fecha, hora, dni, email }) => {
    const startTime = new Date().getTime()
    const { medicos } = this.state
    const { idMedico } = medicos.find(({ nombre }) => nombre === medico)
    const idToken = await firebase.auth.currentUser.getIdToken()

    this.setState({
      loading: true,
    })

    const { data } = await axiosInstance.post(
      '/api/eliminarTurno',
      {
        idMedico,
        fecha,
        hora,
        dni,
        email,
      },
      {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      },
    )

    ReactGA.timing({
      category: 'HTTP Requests',
      variable: 'eliminarTurno',
      value: new Date().getTime() - startTime,
      label: 'POST Eliminar Turno',
    })

    ReactGA.event({
      category: 'Turnos',
      action: 'Cancelar turno',
    })

    this.setState({
      loading: false,
    })

    return data
  }

  generateZoomSignature = async (meetingNumber) => {
    try {
      const startTime = new Date().getTime()
      const idToken = await firebase.auth.currentUser.getIdToken()

      this.setState({
        loading: true,
      })

      const { data } = await axiosInstance.get(
        `/api/generateZoomSignature?meetingNumber=${meetingNumber}`,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        },
      )

      ReactGA.timing({
        category: 'HTTP Requests',
        variable: 'generateZoomSignature',
        value: new Date().getTime() - startTime,
        label: 'GET Generate Zoom Signature',
      })

      this.setState({
        loading: false,
      })

      return data
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false,
      })
    }
  }

  render() {
    const { children } = this.props

    return (
      <TenantContext.Provider
        value={{
          ...this.state,
          setTenant: this.setTenant,
          fetchTurnosDisponibles: this.fetchTurnosDisponibles,
          confirmarTurno: this.confirmarTurno,
          fetchAgendaPaciente: this.fetchAgendaPaciente,
          fetchAgendaMedico: this.fetchAgendaMedico,
          eliminarTurno: this.eliminarTurno,
          generateZoomSignature: this.generateZoomSignature,
        }}
      >
        {children}
      </TenantContext.Provider>
    )
  }
}

TenantProvider.propTypes = {
  children: node.isRequired,
}

const withTenantContext = (mapStateToProps = identity) => (Comp) => (props) => (
  <TenantContext.Consumer>
    {(tenantProps) => {
      const pickedProps = mapStateToProps({ ...tenantProps })
      return <Comp {...props} {...pickedProps} />
    }}
  </TenantContext.Consumer>
)

const useTenantContext = () => {
  const ctx = useContext(TenantContext)

  return ctx
}

export { withTenantContext, useTenantContext, TenantProvider }
