import styled from 'styled-components'

const CalendarWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 300px;

  .CalendarDay__highlighted_calendar {
    &:not(.CalendarDay__selected) {
      background: rgba(82, 89, 214, 0.5);
    }
  }

  .CalendarDay__selected {
    background-color: #f50057;
    border: 1px double #f50057;
  }
`

export const ProgressWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
`

export default CalendarWrapper
