import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { ZoomMtg } from '@zoomus/websdk'

import Link from '@material-ui/core/Link'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Tooltip from '@material-ui/core/Tooltip'
import FileCopyIcon from '@material-ui/icons/FileCopy'
// import IconButton from '@material-ui/core/IconButton'
import { useSnackbar } from 'context/SnackbarContext'
import { useTenantContext } from 'context/TenantContext'
import { useAuth } from 'context/AuthContext'

import firebase from '../../../../firebase/firebase'

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: 8,
    paddingBottom: 0,
  },
  alertLabel: {
    fontWeight: 600,
    fontSize: 14,
  },
  alertActions: {
    marginTop: 18,
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      textTransform: 'none',
      padding: '4px 16px',
      marginLeft: 8,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gCalendarButton: {
    backgroundColor: '#1a73e8',
    color: 'white',
  },
  popoverContent: {
    padding: 24,
    maxWidth: 400,
    overflow: 'auto',
  },
  actionIcon: {
    color: '#8c8c8c',
  },
  copyData: {
    marginLeft: 'auto',
    display: 'flex',
    marginTop: 12,
  },
  linkContainer: {
    wordBreak: 'break-word',
  },
}))

const propTypes = {
  meeting: PropTypes.object.isRequired,
}

const MeetingDetails = ({ meeting }) => {
  const classes = useStyles()
  const { showInfoMsg } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState(null)
  const { generateZoomSignature } = useTenantContext()
  const [fullName, setFullName] = useState('')
  const { user } = useAuth()

  useEffect(() => {
    firebase.firestore
      .collection('users')
      .doc(user.uid)
      .get()
      .then((user) => {
        if (user.exists) {
          const { name, lastName, email } = user.data()
          setFullName(name && lastName ? `${name} ${lastName}` : email)
        }
      })
  }, [user.uid])

  const handleDetailsClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDetailsClose = () => {
    setAnchorEl(null)
  }

  const handleJoinMeetingClick = async () => {
    const { signature, apiKey } = await generateZoomSignature(meeting.id)

    ZoomMtg.init({
      leaveUrl: window.location.href,
      isSupportAV: true,
      success: function () {
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meeting.id,
          userName: fullName,
          apiKey: apiKey,
          passWord: meeting.password,
          success: (success) => {
            document.getElementById('zmmtg-root').style['display'] = 'block'
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          },
        })
      },
    })
  }

  const onCopyMeetingData = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(`
        Videollamada para mi turno online:
          Link: ${meeting['start_url']}
          Password: ${meeting['password']}
      `)
      showInfoMsg('Datos copiados al portapapeles')
      handleDetailsClose()
    }
  }

  return (
    <Alert className={classes.alert} severity="info">
      <Typography className={classes.alertLabel} variant="body1">
        Videoconferencia:
      </Typography>
      Tu videoconferencia con el paciente está agendada en{' '}
      <Link color="secondary" href="https://zoom.us/download" target="blank">
        Zoom
      </Link>{' '}
      para el día{' '}
      {format(new Date(meeting['start_time']), "dd/MM/yyyy 'a las' HH:mm")}. Te
      sugerimos que tengas Zoom instalado en tu computadora para iniciar la
      videollamada. Debés iniciar sesión en Zoom con tu correo electrónico para
      poder acceder a las llamadas.
      <br />
      <div className={classes.alertActions}>
        {/* <Button
          startIcon={<TodayIcon />}
          variant="contained"
          className={classes.gCalendarButton}
        >
          Agendar en Calendar
        </Button> */}

        {/* <Button onClick={handleDetailsClick} color="secondary">
          ¿Tenés problemas para unirte?
        </Button> */}
        <Button
          variant="contained"
          onClick={handleDetailsClick}
          color="secondary"
        >
          Iniciar videollamada
        </Button>
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleDetailsClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popoverContent}>
          <Typography className={classes.linkContainer} variant="body1">
            <b>Link a la conferencia:</b>{' '}
            <Link color="secondary" href={meeting['start_url']} target="blank">
              Click aquí
            </Link>
          </Typography>
          <br />
          <Typography variant="body1">
            <b>Password:</b> {meeting['password']}
          </Typography>
          <Tooltip title="Copiar datos">
            <Button
              className={classes.copyData}
              startIcon={<FileCopyIcon className={classes.actionIcon} />}
              onClick={onCopyMeetingData}
            >
              Copiar datos
            </Button>
          </Tooltip>
        </div>
      </Popover>
    </Alert>
  )
}

MeetingDetails.propTypes = propTypes

export default MeetingDetails
