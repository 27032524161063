import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'

const propTypes = {
  name: PropTypes.string,
}

const FormikTextField = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <TextField
            {...field}
            error={!!meta.error}
            helperText={meta.error || undefined}
            {...props}
          />
        )
      }}
    </Field>
  )
}

FormikTextField.propTypes = propTypes

export default FormikTextField
