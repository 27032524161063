import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import startOfMonth from 'date-fns/startOfMonth'
import moment from 'moment'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import CalendarWrapper, { ProgressWrapper } from 'components/CalendarWrapper'

import { DayPickerSingleDateController } from 'react-dates'
import { useTenantContext } from 'context/TenantContext'
import { useAuth } from 'context/AuthContext'
import firebase from '../../../../firebase/firebase'

const useStyles = makeStyles(() => ({
  paper: {
    padding: 24,
  },
  cardTitle: {
    textAlign: 'left',
  },
}))

const propTypes = {
  selectedDate: PropTypes.object,
  setSelectedDate: PropTypes.func,
}

const CalendarCard = ({ selectedDate, setSelectedDate }) => {
  const { agendaMedico, fetchAgendaMedico } = useTenantContext()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const { user } = useAuth()

  const handleFetchTurnos = async (startDate = new Date()) => {
    try {
      setLoading(true)
      setSelectedDate(null)

      const dbUser = await firebase.firestore
        .collection('users')
        .doc(user.uid)
        .get()

      const { email } = dbUser.data()

      await fetchAgendaMedico(email, moment(startDate))

      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <Grid item xs={12} md={5}>
      <Paper className={classes.paper} elevation={1}>
        <Typography
          className={classes.cardTitle}
          variant="h6"
          color="textPrimary"
          gutterBottom
        >
          Calendario de turnos
        </Typography>
        <br />
        <CalendarWrapper>
          <DayPickerSingleDateController
            hideKeyboardShortcutsPanel
            isDayHighlighted={(date) =>
              !!agendaMedico.find(
                ({ fecha }) => fecha === date.format('DD/MM/YYYY'),
              )
            }
            onNextMonthClick={(date) =>
              handleFetchTurnos(startOfMonth(date.toDate()))
            }
            onPrevMonthClick={(date) =>
              handleFetchTurnos(startOfMonth(date.toDate()))
            }
            onDateChange={(date) => setSelectedDate(date.toDate())}
            date={moment(selectedDate)}
          />
          {loading && (
            <ProgressWrapper
              style={{ position: 'absolute' }}
              className="progress"
            >
              <CircularProgress />
            </ProgressWrapper>
          )}
        </CalendarWrapper>
      </Paper>
    </Grid>
  )
}

CalendarCard.propTypes = propTypes

export default memo(CalendarCard)
