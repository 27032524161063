import React, { memo } from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import isEmpty from 'lodash/isEmpty'

import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

import MeetingDetails from './MeetingDetails'

const propTypes = {
  turno: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fetchAgendaPaciente: PropTypes.func.isRequired,
  eliminarTurno: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'flex-start',
    marginBottom: 12,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    backgroundColor: 'rgb(136, 136, 136)',
    marginRight: 24,
    marginTop: 8,
  },
  label: {
    fontWeight: 600,
    marginRight: 8,
  },
  turnoDetails: {
    '& *': {
      marginBottom: 2,
    },
    flexGrow: 1,
  },
  turnoDetailsItem: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}))

const TurnoListItem = ({
  turno: { paciente, mutual, especialidad, fecha, hora, metadata },
}) => {
  const classes = useStyles()
  const phoneNumber =
    !!metadata && !!metadata.paciente && metadata.paciente.phoneNumber

  return (
    <ListItem
      className={classes.listItemRoot}
      key={`${paciente}${especialidad}${fecha}${hora}`}
    >
      <Hidden mdDown implementation="css">
        <Avatar className={classes.icon}>
          <EventAvailableIcon />
        </Avatar>
      </Hidden>
      <Box
        className={classes.turnoDetails}
        display="flex"
        flexDirection="column"
      >
        <div className={classes.turnoDetailsItem}>
          <Typography className={classes.label} variant="body1">
            Fecha:
          </Typography>
          <Typography variant="body1">{`${fecha} - ${hora}`}</Typography>
        </div>
        <div className={classes.turnoDetailsItem}>
          <Typography className={classes.label} variant="body1">
            Paciente:
          </Typography>
          <Typography variant="body1">
            {startCase(toLower(paciente))}
          </Typography>
        </div>
        <div className={classes.turnoDetailsItem}>
          <Typography className={classes.label} variant="body1">
            Mutual:
          </Typography>
          <Typography variant="body1">{startCase(toLower(mutual))}</Typography>
        </div>
        {!!phoneNumber && (
          <div className={classes.turnoDetailsItem}>
            <Typography className={classes.label} variant="body1">
              Teléfono:
            </Typography>
            <Typography variant="body1">
              {startCase(toLower(phoneNumber))}
            </Typography>
          </div>
        )}
        {metadata && !isEmpty(metadata) && (
          <>
            <div className={classes.turnoDetailsItem}>
              <Typography className={classes.label} variant="body1">
                Modalidad:
              </Typography>
              <Typography variant="body1">
                {startCase(metadata.modalidad)}
              </Typography>
            </div>
            {metadata.meeting && metadata.meeting.id && (
              <MeetingDetails meeting={metadata.meeting} />
            )}
          </>
        )}
      </Box>
    </ListItem>
  )
}

TurnoListItem.propTypes = propTypes

export default memo(TurnoListItem)
