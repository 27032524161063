import React, { memo } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import HomeIcon from '@material-ui/icons/Home'
import ExitIcon from '@material-ui/icons/ExitToApp'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'

import { useLayoutContext } from 'context/LayoutContext'
import { useTenantContext } from 'context/TenantContext'
import firebase from '../firebase/firebase'

const drawerWidth = 265

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: drawerWidth,
    paddingTop: 12,
    backgroundColor: '#1c2866',
  },
  listItemText: {
    color: 'rgba(255,255,255,.7)',
  },
  listIcon: {
    color: 'rgba(255,255,255,.7)',
  },
  logo: {
    filter: 'brightness(0) invert(1)',
    width: 150,
    marginBottom: 32,
    marginLeft: 24,
  },
}))

const SideDrawer = () => {
  const classes = useStyles()
  const {
    tenant: { drawer, returnURL, logo, name: tenantName },
  } = useTenantContext()
  const { mobileDrawerOpen, setMobileDrawerOpen } = useLayoutContext()

  const renderDrawerContent = () => {
    return (
      <List>
        <img src={logo} alt={tenantName} className={classes.logo} />
        <ListItem
          onClick={() => {
            window.open(returnURL)
          }}
          button
          key="home-link"
        >
          <ListItemIcon className={classes.listIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary="Inicio" />
        </ListItem>
        {drawer.map(({ icon, label, link }) => (
          <ListItem
            onClick={() => {
              if (link.includes('firebasestorage.googleapis.com')) {
                firebase.storage
                  .refFromURL(link)
                  .getDownloadURL()
                  .then((url) => {
                    window.open(url)
                  })
              } else {
                window.open(link)
              }
            }}
            button
            key={label}
          >
            <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
            <ListItemText className={classes.listItemText} primary={label} />
          </ListItem>
        ))}
        <ListItem
          onClick={() => {
            firebase.auth.signOut()
          }}
          button
          key="logout-link"
        >
          <ListItemIcon className={classes.listIcon}>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary="Salir" />
        </ListItem>
      </List>
    )
  }

  return (
    <nav aria-label="Mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileDrawerOpen}
          onClose={() => setMobileDrawerOpen(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            className: classes.drawerPaper,
          }}
        >
          {renderDrawerContent()}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          PaperProps={{
            className: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {renderDrawerContent()}
        </Drawer>
      </Hidden>
    </nav>
  )
}

export default memo(SideDrawer)
