import React, { memo } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'

import { useTenantContext } from 'context/TenantContext'
import { useAuth } from 'context/AuthContext'
import TurnoListItem from './TurnoListItem'

const useStyles = makeStyles(() => ({
  turnosList: {
    maxHeight: '70vh',
    overflow: 'scroll',
    width: '100%',
  },
  paper: {
    padding: 24,
  },
  cardTitle: {
    textAlign: 'left',
  },
}))

const ListTurnos = () => {
  const {
    agendaPaciente,
    eliminarTurno,
    fetchAgendaPaciente,
  } = useTenantContext()
  const classes = useStyles()
  const { user } = useAuth()

  if (!agendaPaciente.length) {
    return null
  }

  return (
    <Grid item xs={12} md={7}>
      <Paper className={classes.paper} elevation={1}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography
            className={classes.cardTitle}
            variant="h6"
            color="textPrimary"
            gutterBottom
          >
            Tus próximos turnos
          </Typography>
          <div className={classes.turnosList}>
            {agendaPaciente.map((turno, idx) => (
              <>
                <TurnoListItem
                  eliminarTurno={eliminarTurno}
                  fetchAgendaPaciente={fetchAgendaPaciente}
                  turno={turno}
                  user={user}
                  key={idx}
                />
                <Divider variant="inset" light />
              </>
            ))}
          </div>
        </Box>
      </Paper>
    </Grid>
  )
}

export default memo(ListTurnos)
