import React from 'react'
import ReactDOM from 'react-dom'
import { ZoomMtg } from '@zoomus/websdk'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

ZoomMtg.setZoomJSLib(`${window.location.origin}/libs`, '/av')

ZoomMtg.preLoadWasm()

ZoomMtg.prepareJssdk()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
