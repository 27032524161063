/* eslint-disable react/no-unused-state */

import React, { useContext } from 'react'
import { node, string } from 'prop-types'
import ReactGA from 'react-ga'

import firebase from '../firebase/firebase'

const AuthContext = React.createContext()

class AuthProvider extends React.PureComponent {
  static propTypes = {
    children: node.isRequired,
    tenant: string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      user: null,
      finishedAuthCheck: false,
      tenant: props.tenant,
    }
  }

  componentDidMount() {
    firebase.auth.onAuthStateChanged((user) => {
      this.setState({
        user,
        finishedAuthCheck: true,
      })
    })
  }

  setUser = (user) => {
    ReactGA.set({
      userId: user.uid,
    })

    this.setState({
      user,
    })
  }

  render() {
    const { children } = this.props

    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          setUser: this.setUser,
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
}

const withAuthContext = (Comp) => (props) => (
  <AuthContext.Consumer>
    {(newProps) => <Comp {...props} {...newProps} />}
  </AuthContext.Consumer>
)

const { Consumer } = AuthContext

const useAuth = () => {
  const authContext = useContext(AuthContext)

  return authContext
}

export { AuthProvider, Consumer as AuthConsumer, withAuthContext, useAuth }
