const daysIndex = {
  1: 'LUNES',
  2: 'MARTES',
  3: 'MIERCOLES',
  4: 'JUEVES',
  5: 'VIERNES',
  6: 'SABADO',
  7: 'DOMINGO',
}

export const getDayOfWeekSpanish = (dayOfWeek) => {
  return daysIndex[dayOfWeek]
}
