import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import capitalize from 'lodash/fp/capitalize'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

import CheckIcon from '@material-ui/icons/Check'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { withTenantContext } from 'context/TenantContext'

const propTypes = {
  especialidades: PropTypes.object,
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: 12,
    fontWeight: 600,
  },
  title: {
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 24,
      fontSize: '1.2rem',
    },
  },
  listIcon: {
    marginLeft: 32,
    marginRight: 12,
  },
}))

const Summary = ({ especialidades }) => {
  const classes = useStyles()
  const { validateForm, values } = useFormikContext()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography className={classes.title} variant="h5">
        Verificá los datos antes de confirmar
      </Typography>
      <Box display="flex">
        <CheckIcon color="primary" className={classes.listIcon} />
        <Typography className={classes.label} variant="h6">
          Especialidad:
        </Typography>
        <Typography variant="h6">{capitalize(values.especialidad)}</Typography>
      </Box>
      <Box display="flex">
        <CheckIcon color="primary" className={classes.listIcon} />
        <Typography className={classes.label} variant="h6">
          Médico/a:
        </Typography>
        <Typography variant="h6">
          {especialidades &&
            startCase(
              toLower(
                especialidades[values.especialidad][values.medico].nombre,
              ),
            )}
        </Typography>
      </Box>
      <Box display="flex">
        <CheckIcon color="primary" className={classes.listIcon} />
        <Typography className={classes.label} variant="h6">
          Fecha:
        </Typography>
        <Typography variant="h6">
          {values.fecha.format('DD/MM/YYYY')}
        </Typography>
      </Box>
      <Box display="flex">
        <CheckIcon color="primary" className={classes.listIcon} />
        <Typography className={classes.label} variant="h6">
          Horario:
        </Typography>
        <Typography variant="h6">{values.hora}hs</Typography>
      </Box>
      <Box display="flex">
        <CheckIcon color="primary" className={classes.listIcon} />
        <Typography className={classes.label} variant="h6">
          Modalidad:
        </Typography>
        <Typography variant="h6">{capitalize(values.modalidad)}</Typography>
      </Box>
    </Box>
  )
}

Summary.propTypes = propTypes

export default withTenantContext(({ especialidades }) => ({
  especialidades,
}))(Summary)
