import React, { useEffect, useState, memo } from 'react'

import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import makePrivate from 'components/makePrivate'
import TopBar from 'components/TopBar'
import SideDrawer from 'components/SideDrawer'
import { useTenantContext } from 'context/TenantContext'
import { useAuth } from 'context/AuthContext'
import { useSnackbar } from 'context/SnackbarContext'

import firebase from '../../firebase/firebase'
import { ProgressWrapper } from '../../components/AuthPage/styles'
import ListTurnos from './subcomponents/ListTurnos'
import CalendarCard from './subcomponents/CalendarCard'

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    paddingLeft: 265,
    marginTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  contentGrid: {
    padding: 24,
  },
}))

const HomeMedico = () => {
  const classes = useStyles()
  const { finishedAuthCheck, user } = useAuth()
  const { agendaMedico, fetchAgendaMedico } = useTenantContext()
  const { showErrorMsg } = useSnackbar()
  const [selectedDate, setSelectedDate] = useState(new Date())

  useEffect(() => {
    firebase.firestore
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (user) => {
        if (!user.exists) {
          showErrorMsg('No existe un perfil para este usuario')
          firebase.auth.signOut()
          return
        }

        try {
          await fetchAgendaMedico(user.data().email)
        } catch (error) {
          showErrorMsg('Error al intentar obtener la agenda del paciente')
        }
      })
  }, [fetchAgendaMedico, showErrorMsg, user.uid])

  return (
    <div>
      <CssBaseline />
      <TopBar />
      <SideDrawer />
      <Box className={classes.contentWrapper}>
        {!finishedAuthCheck || !agendaMedico ? (
          <ProgressWrapper>
            <CircularProgress />
          </ProgressWrapper>
        ) : (
          <Grid className={classes.contentGrid} container spacing={3}>
            <CalendarCard
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
            <ListTurnos selectedDate={selectedDate} />
          </Grid>
        )}
      </Box>
    </div>
  )
}

export default memo(makePrivate(HomeMedico))
