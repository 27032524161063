import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/fp/capitalize'
import { Field, useFormikContext } from 'formik'

import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel'
import Box from '@material-ui/core/Box'

import { withTenantContext } from 'context/TenantContext'
import { WS_MODALIDAD_TURNO } from 'utils/constants'

const propTypes = {
  especialidades: PropTypes.object,
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: 250,
    marginTop: 20,
  },
}))

const SelectEspecialidad = ({ especialidades }) => {
  const classes = useStyles()
  const { validateForm } = useFormikContext()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FormLabel component="legend">
        Seleccioná la especialidad y el médico
      </FormLabel>
      <Field name="especialidad">
        {({ field, form: { values } }) => (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="especialidad">Especialidad</InputLabel>
            <Select
              input={
                <OutlinedInput
                  {...field}
                  name="especialidad"
                  id="especialidad"
                />
              }
            >
              <MenuItem value="">
                <em>Ninguna</em>
              </MenuItem>
              {Object.keys(especialidades)
                .filter((especialidadKey) => {
                  // Filtrar especialidades que tengna médicos que cumplan algún horario con atención online
                  // o presencial (de acuerdo al valor ya elegido)
                  const medicos = Object.values(
                    especialidades[especialidadKey],
                  ).filter((medico) => {
                    return Object.values(medico.horarios).some(
                      ({ modalidad }) =>
                        modalidad ===
                        WS_MODALIDAD_TURNO[values.modalidad.toUpperCase()],
                    )
                  })

                  return !!medicos.length
                })
                .map((especialidadKey) => (
                  <MenuItem key={especialidadKey} value={especialidadKey}>
                    {capitalize(especialidadKey)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </Field>
      <Field name="medico">
        {({ field, form: { values } }) => {
          return (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="medico">Médico</InputLabel>
              <Select
                disabled={false}
                input={<OutlinedInput {...field} name="medico" id="medico" />}
              >
                <MenuItem value="">
                  <em>Ninguno</em>
                </MenuItem>
                {values.especialidad &&
                  Object.keys(especialidades[values.especialidad])
                    .filter((medicoKey) => {
                      // Filtrar médicos que tengan algún horario con atención online
                      // o presencial (de acuerdo al valor ya elegido)
                      const horariosMedico =
                        especialidades[values.especialidad][medicoKey].horarios
                      return Object.values(horariosMedico).some(
                        ({ modalidad }) =>
                          modalidad ===
                          WS_MODALIDAD_TURNO[values.modalidad.toUpperCase()],
                      )
                    })
                    .map((medicoKey) => (
                      <MenuItem key={medicoKey} value={medicoKey}>
                        {capitalize(
                          especialidades[values.especialidad][medicoKey].nombre,
                        )}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          )
        }}
      </Field>
    </Box>
  )
}

SelectEspecialidad.propTypes = propTypes

export default withTenantContext(({ especialidades }) => ({ especialidades }))(
  SelectEspecialidad,
)
