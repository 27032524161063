import React from 'react'
import ListAlt from '@material-ui/icons/ListAltOutlined'
import CigorLogo from 'images/cigor.svg'

export default {
  cigor: {
    name: 'Cigor',
    logo: CigorLogo,
    returnURL: 'http://www.cigor.com.ar/',
    drawer: [
      {
        label: 'Descargá el instructivo',
        icon: <ListAlt />,
        link:
          'https://firebasestorage.googleapis.com/v0/b/sgs-turnos-app.appspot.com/o/INSTRUCTIVO_FINAL.PDF',
      },
    ],
  },
}
