import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import { AuthConsumer } from '../context/AuthContext'
import { ProgressWrapper } from './AuthPage/styles'

class PrivateScreen extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  render() {
    const { children } = this.props
    return (
      <AuthConsumer>
        {({ user, finishedAuthCheck, tenant }) => {
          if (!finishedAuthCheck) {
            return (
              <ProgressWrapper>
                <CircularProgress />
              </ProgressWrapper>
            )
          }

          return user ? children : <Redirect to={`/${tenant}`} />
        }}
      </AuthConsumer>
    )
  }
}

/* HOC to make Private Screens using Context Consumer */
function makePrivate(Comp) {
  const privateScreen = (props) => (
    <PrivateScreen>{<Comp {...props} />}</PrivateScreen>
  )
  return privateScreen
}

export default makePrivate
