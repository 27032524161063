import styled from 'styled-components'
import { Paper } from '@material-ui/core'

/* Mobile first */
export const Main = styled.main`
  width: auto;
  display: block;
  margin-left: 24px;
  margin-right: 24px;

  @media (min-width: 768px) {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const ProgressWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledPaper = styled(Paper).attrs({
  classes: {
    root: 'customRoot',
  },
})`
  &&.customRoot {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    height: 390px;
  }
`

export const StyledForm = styled.form`
  width: 100%;
`

export const SubmitWrapper = styled.div`
  margin-top: 24px;
`

export const LogoImg = styled.img`
  max-width: 200px;
`
