import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'
import moment from 'moment'
import parse from 'date-fns/parse'
import getDay from 'date-fns/getDay'

import Box from '@material-ui/core/Box'
import FormLabel from '@material-ui/core/FormLabel'
import CircularProgress from '@material-ui/core/CircularProgress'

import { DayPickerSingleDateController } from 'react-dates'

import { useTenantContext } from 'context/TenantContext'
import CalendarWrapper, { ProgressWrapper } from 'components/CalendarWrapper'
import { getDayOfWeekSpanish } from 'utils/wsDates'

const propTypes = {
  name: PropTypes.string,
}

const SelectFecha = ({ name = 'fecha' }) => {
  const [_, meta, helpers] = useField(name) // eslint-disable-line
  const { values, validateForm } = useFormikContext()
  const {
    fetchTurnosDisponibles,
    turnosDisponibles,
    medicos,
    loading,
  } = useTenantContext()

  const { value } = meta
  const { setValue } = helpers

  // Filtrar fechas que coinciden con los días que el médico atiende bajo la modalidad elegida
  const diasDisponibles = Object.keys(turnosDisponibles).filter((strDate) => {
    const dayOfWeekSpa = getDayOfWeekSpanish(
      getDay(parse(strDate, 'dd/MM/yyyy', new Date())),
    )

    return !!medicos.find(({ idMedico }) => idMedico === values.medico)
      .horarios[dayOfWeekSpa]
  })

  useEffect(() => {
    fetchTurnosDisponibles(values.medico)
    validateForm()
  }, [validateForm, fetchTurnosDisponibles, values.medico])

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FormLabel component="legend">Seleccioná una fecha disponible</FormLabel>
      <br />
      <CalendarWrapper>
        <DayPickerSingleDateController
          hideKeyboardShortcutsPanel
          isDayHighlighted={(date) =>
            !!diasDisponibles.find((k) => k === date.format('DD/MM/YYYY'))
          }
          onNextMonthClick={(date) =>
            fetchTurnosDisponibles(values.medico, moment(date).startOf('month'))
          }
          onPrevMonthClick={(date) =>
            fetchTurnosDisponibles(values.medico, moment(date).startOf('month'))
          }
          onDateChange={(date) => {
            if (diasDisponibles.find((k) => k === date.format('DD/MM/YYYY'))) {
              setValue(date)
            }
          }}
          date={value}
        />
        {loading && (
          <ProgressWrapper className="progress">
            <CircularProgress />
          </ProgressWrapper>
        )}
      </CalendarWrapper>
    </Box>
  )
}

SelectFecha.propTypes = propTypes

export default memo(SelectFecha)
